import { GetServerSideProps } from "next";

import Head from "next/head";

import { AxiosError } from "axios";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { ensureArray } from "utils";

import { SourcingCampaignApi } from "@api";
import { CampaignListPageContent, NewCampaignModal } from "@components";
import { QUERY_KEYS } from "@constants";
import { useApp } from "@contexts";
import { loadTranslations } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { NextApplicationPage, SourcingCampaign } from "@typings";

const SourcingCampaignList: NextApplicationPage = () => {
  const { t } = useTranslation(["header", "campaign-list"]);
  loadTranslations("campaign-list");
  loadTranslations("header");

  const { campaignCreated, setCampaignCreated } = useApp();

  const { data, isLoading } = useQuery<Array<SourcingCampaign>, AxiosError>({
    queryKey: [QUERY_KEYS.SOURCING_CAMPAIGNS],
    queryFn: SourcingCampaignApi.list,
    meta: {
      errorMessage: t("campaign-list:loading_error"),
    },
  });

  return (
    <>
      <Head>
        <title>{t("start_sourcing_head")}</title>
      </Head>

      <CampaignListPageContent campaigns={ensureArray(data)} isLoading={isLoading} />
      <NewCampaignModal open={campaignCreated} setOpen={setCampaignCreated} />
    </>
  );
};

export default SourcingCampaignList;
SourcingCampaignList.auth = true;

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: { ...(await serverSideTranslations(locale ?? "fr", ["header", "campaign-list"])) },
  };
};
